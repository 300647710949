
import Vue from '@/vueTyped';
import { CurrentUser, Investigation, Post, StructuredAnswer, StructuredQuestion, Photo, WeatherDataRecently } from '../types';
import flatMap from 'lodash/flatMap';
import moment from 'moment';
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import PostComments from '@/components/PostComments/Index.vue';
import RouteNames from '@/router/names';
import orderBy from 'lodash/orderBy';
import { ISEECHANGE_TRENDS_USER_ID } from '@/config';
import { longAddressFromComponents } from '@/util.location';
import { makeSatelliteImageSrc } from '@/util.mapbox';
import AutoHeightTextArea from './AutoHeightTextArea.vue';
import TransitionExpand from './TransitionExpand.vue';
import ViewDetector from './ViewDetector.vue';
import DeviationDescription from '@/components/HistoricalWeatherPrompt/DeviationDescription.vue';
import tidyWeatherAnalysis, { TidyWeatherAnalysis } from '@/util.tidy-weather-analysis';
import { downloadZip } from '@/util.posts';
import OverlayModal from '@/layouts/OverlayModal.vue';
import ModalContent from './HistoricalWeatherPrompt/ModalContent.vue';
import PostCardReportModal from './PostCardReportModal.vue';
import SlippyCarousel from './SlippyCarousel.vue';
import UniversalImage from './UniversalImage.vue';

export default Vue.extend({
    name: 'PostCard',
    components: {
        PostComments,
        MarkdownOutput,
        AutoHeightTextArea,
        TransitionExpand,
        ViewDetector,
        DeviationDescription,
        OverlayModal,
        ModalContent,
        PostCardReportModal,
        SlippyCarousel,
        UniversalImage,
    },
    props: {
        post: {
            type: Object as () => Post,
            required: true,
        },
        // Shows comments, data expanded.
        expandedView: Boolean,
        loading: {
            type: Boolean,
            default: false,
        },
        uniqueId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            gallery: {
                open: false,
                index: 0,
            },
            dataTabIndex: 0,
            abnormalWeatherDialogOpen: false,
            showComments: false,
            isBeingEdited: false,
            editableText: '',
            saveInProgress: 0,
            reportModalOpen: false,
            showingDeleteDialog: false,
            isBeingDeleted: false,
        };
    },
    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
        roughLocation(): string {
            return longAddressFromComponents(this.post.addressComponents ?? {});
        },
        location(): string {
            const { text, streetNumber, streetName, city, place, state, zipcode } = this.post.addressComponents ?? {};
            if (text) {
                return text.split(`, ${state} ${zipcode}`)[0];
            } else if (streetNumber) {
                return `${streetNumber} ${streetName}, ${city || place}`;
            } else {
                return this.roughLocation;
            }
        },
        weatherRecently(): WeatherDataRecently | {} {
            const TIME_RANGE = 12;
            return this.post.weatherData.currently?.recently?.find(entry => entry.lastXHours === TIME_RANGE) ?? {};
        },
        abnormalWeather(): TidyWeatherAnalysis | null {
            if (this.post.weatherAnalyticsData) {
                const tidyData = tidyWeatherAnalysis(this.post.weatherAnalyticsData);
                if (tidyData.highDeviation > 1 || tidyData.lowDeviation > 1) {
                    return tidyData;
                }
            }
            return null;
        },
        sortedMedia(): (Photo | any)[] {
            return [
                ...orderBy(this.post.media, 'created_at'),
                // { id: 'TESTING', additionalInfo: { mux: { playback_id: 'zwiaIfCf7100o01HUZ86cx6pgE00yUdbc9DcNfbZGsFSDA' } } },
            ];
        },
        sortedInvestigations(): Investigation[] {
            const storeInvestigations = this.$store.state.investigations.items;
            const postInvestigations = this.post.investigations.map(id => storeInvestigations.find(i => i.id === id));
            const presentInvestigations = postInvestigations.filter(Boolean) as Investigation[];
            return orderBy(presentInvestigations, 'name');
        },
        isOwnPost(): boolean {
            return this.currentUser?.id === this.post?.user;
        },
        canDownloadZip(): boolean {
            return !this.$vuetify.breakpoint.smAndDown && Boolean(this.post.showSatelliteImage);
        },
        isTrendsPost(): boolean {
            return this.post.userObj.id === ISEECHANGE_TRENDS_USER_ID
        },
        showExpandedContents(): boolean {
            return this.expandedView;
        },
        structuredDataFromUser(): StructuredQuestion[] {
            const questions = flatMap(this.$store.state.investigations.items, (i: Investigation) => i.structuredQuestions);
            if (this.post.structuredAnswers) {
                return (this.post.structuredAnswers.map((a) => ({
                    id: null,
                    ...questions.find((q) => q.id === a.questionId),
                    answer: a,
                }))
                .filter((q) => q.id) as StructuredQuestion[])
                .sort((q1: StructuredQuestion, q2: StructuredQuestion) => q1.order - q2.order) ;
            }
            return [];
        },
        promotedQuestion(): StructuredQuestion | null {
            if (this.structuredDataFromUser.length) {
                return this.structuredDataFromUser.filter((a) => a.answer && a.answer.promotedQuestion)[0] || null;
            }
            return null;
        },
        investigationsSeparator(): string {
            const allNames = this.sortedInvestigations.map(investigation => investigation.name);
            return allNames.join(' ').includes(',') ? ';' : ',';
        },
    },
    watch: {
        'post.textBody': {
            immediate: true,
            handler(textBody) {
                this.editableText = textBody;
            },
        }
    },
    methods: {
        getSatelliteImage(post: Post, width = 500, height = 500, zoom = 17) {
            return makeSatelliteImageSrc({
                center: [post.lng, post.lat],
                pin: [post.lng, post.lat],
                zoom,
                width,
                height,
            });
        },
        downloadZip() {
            try {
                downloadZip(this.post.id);
            } catch (error) {
                console.error(error);
            }
        },
        cleanDecimal(n: number, places: number) {
            const fixed = n.toFixed(places);
            return places === 0 ? fixed : fixed.replace(/0+$/, '').replace(/\.$/, '');
        },
        getAnswerVal(q: StructuredQuestion | null, a: StructuredAnswer | null): string | string[] {
            if (!q || !a) {
                return '';
            }
            let value: string | string[] = a.literalValue ?? '';
            let unit = '';
            switch (q.dataType) {
                case 'DECIMAL':
                case 'NUMERIC':
                    unit = q.unit.toLowerCase();
                    if (unit === 'inches' || unit == 'mm') {
                        value = this.cleanDecimal(a.numericValue ?? 0, 4);
                        if (unit === 'mm' && a.numericValue && a.numericValue > 10) {
                            value = this.cleanDecimal((a.numericValue ?? 0) / 10, 4);
                            unit = 'cm';
                        }
                    } else {
                        value = this.cleanDecimal(a.numericValue ?? 0, 4);
                    }
                    break;
                case 'DATETIME':
                    value = moment(`${a.datetimeValue}`).format('hh:mm A');
                    break;
                case 'BOOLEAN':
                    value = a.booleanValue ? 'yes' : 'no';
                    unit = '';
                    break;
                case 'TIMERANGE':
                    value = (a.timeRangeValue ?? []).map(dateString => dateString ? moment(dateString).format('l LT') : '?').join('–');
                    break;
                case 'SELECT':
                    if (q.additionalInfo.multiple) {
                        try {
                            value = JSON.parse(value);
                        } catch (error) {
                            console.warn(`Couldn't parse SELECT/multiple answer ${value}`);
                        }
                    }
                    break;
                default:
                    break;
            }
            if (Array.isArray(value)) {
                value = value.join(', ');
            }
            return `${value} ${unit}`.trim();
        },
        async openGallery(index: number) {
            this.gallery.open = true;
            await this.$nextTick();
            (this.$refs.gallery as any)?.scrollTo(index);
        },
        showShare() {
            this.$store.dispatch('shareContent', { post: this.post });
        },
        async saveEdits() {
            try {
                this.saveInProgress += 1;

                await this.$store.dispatch('updatePost', {
                    post: this.post,
                    updates: {
                        textBody: this.editableText
                    },
                });

                this.isBeingEdited = false;
            } catch (error) {
                console.error(error);
            } finally {
                this.saveInProgress -= 1;
            }
        },
        async toggleEditing() {
            this.isBeingEdited = !this.isBeingEdited;
            // NOTE: $nextTick doesn't work here. I think the menu activator refocuses after it's closed.
            await new Promise(resolve => setTimeout(resolve, 50));
            if (this.isBeingEdited) {
                console.log('FIELD', ((this.$refs.editField as Vue)?.$el as HTMLTextAreaElement)?.focus);
                ((this.$refs.editField as Vue)?.$el as HTMLTextAreaElement)?.focus();
            }
        },
        async deletePost() {
            this.isBeingDeleted = true;
            const { error } = await this.$store.dispatch('deletePost', { id: this.post.id });
            if (error) {
                this.showingDeleteDialog = false;
            } else {
                if (this.expandedView) {
                    let redirectRoute = { name: RouteNames.POSTS };
                    if (this.$store.state.previousRoutes.length !== 0) {
                        redirectRoute = this.$store.state.previousRoutes[this.$store.state.previousRoutes.length - 1];
                    }
                    this.$router.replace(redirectRoute);
                }
            }
        },
    },
});
